import { PageId } from "./pages";

export enum CountryCode {
  DE = "DE",
  ES = "ES",
  FR = "FR",
}

export type Locale = "de" | "es" | "fr";
export const availableLocales: Locale[] = ["de", "es", "fr"];

export interface ContactMethodElement {
  destination: string;
  type: string;
  phoneNumberToShow?: string;
  messageToShow?: string;
}

export interface ZipCodeValidation {
  min: number;
  max: number;
}

export interface CountrySubsetConfiguration {
  name: string;
  defaultLocale: Locale;
  countryCode: CountryCode;
}

export interface CountryConfiguration extends CountrySubsetConfiguration {
  idDocumentRequired: boolean;
  listTaxes: boolean;
  alternativeDisclaimer: boolean;
  phoneNumberLength: Record<string, number>;
  phoneNumberPrefix: string;
  contactMethods: ContactMethodElement[];
  multiplePetsDiscount: number;
  hasTattooAnswer: boolean;
  hasComplianceErrorMessage: boolean;
  smallPrintPages: boolean;
  euFederLogos: boolean;
  zipCode: ZipCodeValidation;
  alternativeOnboardingEmailTitle: boolean;
  becasDiscount: boolean;
  allowChangePolicyStartDate: boolean;
  fakeChipNumber: boolean;
  pagesWithChatLink: PageId[];
  chatLink: boolean;
  addOtherInGenderOptions: boolean;
  preContractNotificationIsRequired: boolean;
  hasDescriptionInAccessPage: boolean;
  paymentMethods: string[];
  glAddon: boolean;
  alternativeHealthProductSelector: boolean;
  phoneNumberInPreQuotes: boolean;
  phoneNumberBeforePetParentPage: boolean;
  scheduleCallOption: boolean;
  contactLinks: boolean;
  fcMediaTracking: boolean;
  baiLinkOnPrevention: boolean;
  baiLinkOnQuotesCoverages: boolean;
}

export const countries: Record<CountryCode, CountryConfiguration> = {
  DE: {
    idDocumentRequired: false,
    defaultLocale: "de" as Locale,
    countryCode: CountryCode.DE,
    name: "Deutschland",
    listTaxes: true,
    alternativeDisclaimer: true,
    phoneNumberLength: { min: 8, max: 12 },
    phoneNumberPrefix: "49",
    contactMethods: [
      {
        destination: "https://wa.me/message/AL336C7QS7K4G1?utm_term=maincta",
        type: "whatsapp",
        messageToShow: "common.whatsapp_contact.messageToShow",
      },
    ],
    multiplePetsDiscount: 50,
    hasTattooAnswer: true,
    hasComplianceErrorMessage: false,
    smallPrintPages: false,
    euFederLogos: false,
    zipCode: { min: 5, max: 5 },
    alternativeOnboardingEmailTitle: true,
    becasDiscount: true,
    allowChangePolicyStartDate: true,
    fakeChipNumber: true,
    pagesWithChatLink: [
      PageId.uncovered,
      PageId.quotes,
      PageId.hasChip,
      PageId.noChip,
      PageId.petParent,
      PageId.address,
      PageId.access,
      PageId.chip,
      PageId.petFamily,
      PageId.details,
      PageId.landing,
    ],
    chatLink: true,
    addOtherInGenderOptions: false,
    preContractNotificationIsRequired: false,
    hasDescriptionInAccessPage: false,
    paymentMethods: ["card", "sepa_debit", "paypal"],
    glAddon: false,
    alternativeHealthProductSelector: true,
    phoneNumberInPreQuotes: true,
    phoneNumberBeforePetParentPage: false,
    contactLinks: true,
    scheduleCallOption: true,
    fcMediaTracking: true,
    baiLinkOnPrevention: true,
    baiLinkOnQuotesCoverages: false,
  },
  ES: {
    idDocumentRequired: true,
    defaultLocale: "es" as Locale,
    countryCode: CountryCode.ES,
    name: "España",
    listTaxes: false,
    alternativeDisclaimer: false,
    phoneNumberLength: { min: 9, max: 9 },
    phoneNumberPrefix: "34",
    contactMethods: [
      {
        destination: "https://wa.me/message/Q5KWYO5LVFTYK1?utm_term=maincta",
        type: "whatsapp",
      },
    ],
    multiplePetsDiscount: 25,
    hasTattooAnswer: false,
    hasComplianceErrorMessage: true,
    smallPrintPages: true,
    euFederLogos: true,
    zipCode: { min: 5, max: 5 },
    alternativeOnboardingEmailTitle: false,
    becasDiscount: true,
    allowChangePolicyStartDate: false,
    fakeChipNumber: false,
    pagesWithChatLink: [
      PageId.uncovered,
      PageId.quotes,
      PageId.hasChip,
      PageId.noChip,
      PageId.petParent,
      PageId.address,
      PageId.access,
      PageId.chip,
      PageId.petFamily,
      PageId.smallPrint,
      PageId.preExistencesConditions,
      PageId.waitingPeriod,
      PageId.details,
      PageId.landing,
    ],
    chatLink: false,
    addOtherInGenderOptions: true,
    preContractNotificationIsRequired: false,
    hasDescriptionInAccessPage: false,
    paymentMethods: ["card"],
    glAddon: true,
    alternativeHealthProductSelector: false,
    phoneNumberInPreQuotes: false,
    phoneNumberBeforePetParentPage: true,
    scheduleCallOption: false,
    contactLinks: false,
    fcMediaTracking: false,
    baiLinkOnPrevention: false,
    baiLinkOnQuotesCoverages: true,
  },
  FR: {
    idDocumentRequired: false,
    defaultLocale: "fr" as Locale,
    countryCode: CountryCode.FR,
    name: "France",
    listTaxes: false,
    alternativeDisclaimer: true,
    phoneNumberLength: { min: 9, max: 10 },
    phoneNumberPrefix: "33",
    contactMethods: [
      {
        destination: "tel:+33451420381",
        type: "phone",
        phoneNumberToShow: "04 51 42 03 81",
      },
      {
        destination: "https://wa.me/message/UC7OSSJPSYO3L1?utm_term=maincta",
        type: "whatsapp",
      },
    ],
    multiplePetsDiscount: 25,
    hasTattooAnswer: false,
    hasComplianceErrorMessage: true,
    smallPrintPages: false,
    euFederLogos: false,
    zipCode: { min: 5, max: 5 },
    alternativeOnboardingEmailTitle: false,
    becasDiscount: true,
    allowChangePolicyStartDate: true,
    fakeChipNumber: true,
    pagesWithChatLink: [
      PageId.uncovered,
      PageId.quotes,
      PageId.hasChip,
      PageId.noChip,
      PageId.petParent,
      PageId.address,
      PageId.access,
      PageId.chip,
      PageId.petFamily,
      PageId.details,
    ],
    chatLink: false,
    addOtherInGenderOptions: true,
    preContractNotificationIsRequired: true,
    hasDescriptionInAccessPage: true,
    paymentMethods: ["card", "sepa_debit"],
    glAddon: false,
    alternativeHealthProductSelector: false,
    phoneNumberInPreQuotes: true,
    phoneNumberBeforePetParentPage: false,
    scheduleCallOption: false,
    contactLinks: true,
    fcMediaTracking: true,
    baiLinkOnPrevention: false,
    baiLinkOnQuotesCoverages: true,
  },
};
